exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-propos-de-l-agenda-tsx": () => import("./../../../src/pages/a-propos-de-l-agenda.tsx" /* webpackChunkName: "component---src-pages-a-propos-de-l-agenda-tsx" */),
  "component---src-pages-agenda-des-activites-et-des-conferences-tsx": () => import("./../../../src/pages/agenda-des-activites-et-des-conferences.tsx" /* webpackChunkName: "component---src-pages-agenda-des-activites-et-des-conferences-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-therapeutes-et-professionnels-tsx": () => import("./../../../src/pages/therapeutes-et-professionnels.tsx" /* webpackChunkName: "component---src-pages-therapeutes-et-professionnels-tsx" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-list-practitioners-by-category-js": () => import("./../../../src/templates/list-practitioners-by-category.js" /* webpackChunkName: "component---src-templates-list-practitioners-by-category-js" */),
  "component---src-templates-list-practitioners-by-region-province-city-js": () => import("./../../../src/templates/list-practitioners-by-region-province-city.js" /* webpackChunkName: "component---src-templates-list-practitioners-by-region-province-city-js" */),
  "component---src-templates-practitioner-js": () => import("./../../../src/templates/practitioner.js" /* webpackChunkName: "component---src-templates-practitioner-js" */)
}

